.m-card {
  width: 242px;
  border-radius: 8px;
  margin-bottom: 20px;
  color: #fff;
  background: linear-gradient(to bottom right, #D5DEED, #BDCBDE, #A4B7CF);
}
.m-card-info {
  padding: 22px 16px;
  cursor: pointer;
  min-height: 100px;
}

.m-card-info h5 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.m-card-info p {
  font-size: 13px;
  font-weight: 400;
}

.m-card .ant-radio-group {
  width: 100%;
  padding: 6px 16px;
  background: rgba(0,0,0,0.1);
  border-radius: 0 0 8px 8px;
}

.m-card .ant-radio-wrapper {
  color: #fff;
}