.card-edit {
    background: #F8F8F8;
}

.card-edit .container {
    padding-top: 20px;
}

.card-edit .query-box {
    padding-top: 20px;
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: 500px auto 120px 120px;
}

.card-edit .table-column {
    background: #fff;
}

.card-edit th.table-column {
    text-align: center;
    background: #F9FDFF;
}

.card-edit .ant-table-footer {
    background: #fff;
}

.card-edit .table-footer {
    display: grid;
    grid-template-columns: auto 610px 300px;
    justify-items: right;
    align-items: center;
    color: #9B9B9B;
    padding: 10px 0;
}

.card-edit .table-footer button {
    background: #EBF7FD;
    border: 1px solid #5BC1EC;
    border-radius: 4px;
    color: #5BC1EC;
    padding: 10px 20px;
}

.card-edit .table-footer button.creator-center-btn {
    background: #5BC1EC;
    color: #fff;
    margin-left: 10px;
}

.modal-bag {
    border-bottom: 1px solid #EEEEEE;;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
    margin-top: -10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.modal-card {
    display: grid;
    grid-template-columns: 420px 180px;
}

.modal-mini-program-card {
    padding-top: 20px;
}

.ccc {
    background: red;
}
