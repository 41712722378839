a,
a:hover {
    text-decoration: none !important;
}

header.c-header {
    height: 80px;
    position: relative;
}

header.c-header img {
    height: 44px;
    margin-left: -15px;
    border-radius: 8px;
}

header.c-header .navbar-header span {
    margin-left: 10px;
    font-size: 20px;
    line-height: 80px;
    color: #32b6f4;
    font-weight: bold;
}

header.c-header ul {
    line-height: 80px;
}

header.c-header ul li {
    width: 110px;
    text-align: center;
    font-size: 15px;
}

header.c-header ul li span {
    line-height: 50px;
    padding: 8px;
}

header.c-header ul li.active span {
    color: #32b6f4;
    border-bottom: 2px solid #32b6f4;
}
header.c-header .navbar-right {
    margin-right: -32px;
}
.navbar-login {
    background: #32b6f4;
    color: #fff;
    cursor: pointer;
    border-radius: 6px;
    padding: 14px 32px;
    /* box-shadow: 2px 2px 4px #999; */
}
.c-header .sub-nav {
    display: flex;
    position: absolute;
    top: 80px;
    right: 20px;
    list-style: none;
    padding: 30px 0;
    background: #fff;
    line-height: normal;
    z-index: 99;
    box-shadow: 0 3px 7px 0 rgb(0,0,0, .1);
}
.c-header .sub-nav li{
    cursor: pointer;
}
.c-header .sub-nav li:not(:first-child){
    border-left: 1px solid #eee;
}
header.c-header .sub-nav li span {
    line-height: normal;
    padding: 4px 12px;
    border-radius: 4px;
}
header.c-header .sub-nav li span:hover,
.sub-nav-li_active{
    background: #40a9ff;
    color: #fff;
}