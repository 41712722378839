/* components/correct-line/correct-line.wxss */
.c-correct-line .complete {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0;
  font-size: 24px;
}

.c-correct-line .complete.green {
  color: #52D0A0;
}

.c-correct-line .complete.red {
  color: #FC8181;
}

.c-correct-line .complete .line {
  flex-grow: 2;
  height: 1px;
  background: #aaa;
}

.c-correct-line .complete.green .line {
  background: #d6fced;
}

.c-correct-line .complete.red .line {
  background: #fde4e4;
}

.c-correct-line .complete .text {
  margin: auto 8px;
}

.c-correct-line .complete img {
  margin: 0 10px;
  width: 30px;
}