.c-container {
    background: #fff;
    border-radius: 10px;
}

.c-container>div {
    padding: 18px;
}

.c-container-title-box {
    border-bottom: 1px solid #EEEEEE;
}

.c-container-title {
    color: #222222;
    font-size: 18px;
    font-weight: bold;
    margin-right: 20px;
}

.c-container-desc {
    font-size: 14px;
    color: #999999;
}
