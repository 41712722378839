.c-mini-program-package-page {
    width: 280px;
    min-height: 400px;
    border-radius: 8px;
    box-shadow: 0 4px 7px 0 rgba(185,188,189,0.4);
}

.c-mini-program-package-page .header {
    height: 140px;
    border-radius: 8px;
    background: #3CB7F2;
    color: #fff;
    padding: 10px 10px;
    display: grid;
    grid-template-columns: 55px auto;
}

.c-mini-program-package-page .avatar {
    width: 45px;
    border-radius: 100%;
}

.c-mini-program-package-page .author {
    font-size: 16px;
    font-weight: bold;
}

.c-mini-program-package-page .title {
    font-size: 14px;
}

.c-mini-program-package-page .desc {
    font-size: 12px;
}

.c-mini-program-package-page .desc.webkit {
    width: 250px;
    -webkit-transform: scale(0.85);
    transform-origin: top left;
}

.c-mini-program-package-page .text-line {
    padding: 10px;
    padding-bottom: 0;
    font-size: 12px;
    color: #B6B6B6;
    display: flex;
    justify-content: space-between;
}

.c-mini-program-package-page .sub-bag-box {
    display: grid;
    grid-template-columns: 50% 50%;
}

.c-mini-program-package-page .sub-bag-box .sub-bag {
    border: 1px solid #EEEEEE;
    border-radius: 10px;
    margin: 10px;
    padding: 10px
}

.c-mini-program-package-page .btn-box {
    margin: 0!important;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px;
}
