.c-cute-modal-box .ant-modal-content {
    border-radius: 15px;
}

.c-cute-modal .body {
    padding: 20px;
}

.c-cute-modal .body .content {
    text-align: center;
    margin: 20px;
}

.c-cute-modal .body .input-box {
    border: 1px solid #32B6F4;
    border-radius: 4px;
    padding: 8px;
    display: grid;
    grid-template-columns: 75% 25%;
}

.c-cute-modal .body .input-box input {
    border: none;
    outline: none;
    width: 100%;
}

.c-cute-modal-box .body .input-box .with-end {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

.c-cute-modal .footer {
    padding: 20px;
    padding-top: 0;
    display: flex;
    justify-content: space-between;
}
