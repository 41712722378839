.container {
    width: 1180px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

button.circle-btn {
    min-width: 120px;
    height: 40px;
    line-height: 40px;
    background: #32B6F4;
    border: none;
    color: #fff;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 999px;
}

button.base-btn {
    height: 40px;
    line-height: 40px;
    background: #32B6F4;
    border: none;
    color: #fff;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    min-width: 100px;
    font-size: 16px;
}

button.cancel {
    background: #EEEEEE;
    color: #ABABAB;
}

button.blue {
    background: #32B6F4;
}

button.red {
    background: #FC8181;
}

button.white {
    background: #fff;
    color: #32B6F4;
    border: 1px solid #32B6F4;
}

button.mini-btn {
    width: 86px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #EEEEEE;
    color: #32B6F4;
}

table th.table-column {
    text-align: center;
    background: #F9FDFF;
}
