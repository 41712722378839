.c-custom-dropdown {
    display: flex;
    padding: 10px 0;
}

.c-custom-dropdown .title {
    min-width: 100px;
    text-align: right;
    color: #333333;
    margin-right: 15px;
    font-size: 14px;
    line-height: 38px;
}

.c-custom-dropdown .content {
    min-width: 180px;
    background: #F8F8F8;
    border: 1px solid #EEEEEE;
    border-radius: 4px;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.c-custom-dropdown .required {
    color: #FC8181;
    margin-left: 6px;
}
