.home section .banner {
    height: 540px;
    background: url(https://xiaoka-1254962201.cos.ap-shanghai.myqcloud.com/%E5%A4%B4%E5%9B%BE.png);
    background-position: center;
}

.home section .banner img {
    width: 100%;
    height: 100%;
}

.home section .container .row .font {
    font-size: 38px;
}

.home section .container .row .bold {
    font-weight: bold;
}

.home section .container .row.three-part .img {
    margin-top: 150px;
    margin-bottom: 30px;
    text-align: center;
}

.home section .container .row.three-part h3 {
    font-size: 16px;
    font-weight: bold;
    margin-left: 20px;
    margin-right: 20px;
}

.home section .container .row.three-part p {
    font-size: 12px;
    margin-left: 20px;
    margin-right: 20px;
}
