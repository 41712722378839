.online {
    background: #F8F8F8;
}

.online font {
    margin-left: 5px;
    margin-right: 5px;
}

.online .section {
    margin: 50px 150px;
    padding: 24px;
    padding-bottom: 30px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 8px 14px 0 rgba(185,188,189,0.21);
}

.online .section .header {
    border-bottom: 1px solid #eee;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.online .section .header .package-name {
    line-height: 32px;
    color: #b5b5b5;
}

.online .section .header img {
    width: 20px;
    height: 22px;
}

.online .section .question {
    color: #333;
}

.online .section .option-box {
    margin: 0 auto;
    margin-top: 250px;
    width: 400px;
    display: flex;
    justify-content: space-between;
}

.online .section .next-box {
    margin-top: 100px;
    text-align: center;
}

.online .section .correct-line {
    margin: 50px 0;
}

.online .section .empty {
    padding-top: 80px;
    padding-bottom: 80px;
}

.online .tips {
    margin: 50px 150px;
    font-size: 14px;
}

.online .blue-font {
    color: #42AFE2;
}