.c-upload-modal-box .ant-modal-content {
    border-radius: 15px;
}

.c-upload .title {
    height: 50px;
    line-height: 50px;
    background: #32B6F4;
    color: #fff;
    text-align: center;
    font-size: 20px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.c-upload .body {
    padding: 20px 30px;
}

.c-upload .body p {
    text-align: center;
    color: #333333;
}

.c-upload .footer {
    padding-bottom: 20px;
    text-align: center;
}
