.c-custom-input {
    display: flex;
    /* align-items: center; */
    padding: 10px 0;
}

.c-custom-input .title {
    min-width: 100px;
    text-align: right;
    color: #333333;
    margin-right: 15px;
    font-size: 14px;
    line-height: 38px;
}

.c-custom-input .input-box {
    display: flex;
    align-items: center;
}

.c-custom-input .input-box input {
    min-width: 280px;
    background: #F8F8F8;
    border: 1px solid #EEEEEE;
    border-radius: 4px;
    padding: 8px;
}

.c-custom-input .input-box .required {
    color: #FC8181;
    margin-left: 6px;
}

.c-custom-input .input-box .add-sub {
    width: 20px;
    height: 20px;
    line-height: 15px;
    text-align: center;
    font-size: 24px;
    font-weight: 200;
    border: 1px solid #6C6C6C;
    border-radius: 100%;
    color: #6C6C6C;
    margin-left: 10px;
    cursor: pointer;
}