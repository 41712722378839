.c-card {
    width: 280px;
    padding: 20px;
    background: #fff;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 8px 14px 0 rgba(185,188,189,0.31);
}

.c-card .title {
    font-size: 18px;
    font-weight: bold;
    line-height: 32px;
}

.c-card .text {
    font-size: 14px;
    line-height: 1.5;
}

.c-card .btns {
    margin-top: 30px;
    margin-bottom: 10px;
}

.c-card .btns button {
    margin: 0 10px;
    min-width: 110px;
    padding: 8px 20px;
    background: linear-gradient(#46D9FE, #33A8DE);
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
}

.c-card .btns button.grey {
    background: linear-gradient(#eee, #aaa);
    color: #fff;
}

.c-card .grey {
    color: #999;
}

.c-card .bold {
    font-weight: bold;
}
