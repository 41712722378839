.c-custom-modal-box .ant-modal-content {
    border-radius: 15px;
}

.c-custom-modal-title {
    height: 50px;
    line-height: 50px;
    background: #32B6F4;
    color: #fff;
    text-align: center;
    font-size: 20px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.c-custom-modal-body {
    padding: 20px;
}

.c-custom-modal-footer {
    border-top: 1px solid #EEEEEE;
    text-align: right;
    padding: 20px;
}

.c-custom-modal-footer button {
    margin-left: 20px;
}
