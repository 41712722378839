.c-mini-program-card {
    margin-top: -10px;
    width: 260px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 7px 0 rgba(185,188,189,0.4);
    min-height: 340px;
}

.c-mini-program-card .header {
    color: #B6B6B6;
    line-height: 2;
    border-bottom: 1px solid #EEEEEE;
    margin-bottom: 10px;
    min-height: 28px;
    display: flex;
    justify-content: space-between;
}

.c-mini-program-card .header .left span {
    margin-right: 4px;
}

.c-mini-program-card .header img {
    width: 20px;
    height: 20px;
    margin: 4px;
}
