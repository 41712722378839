.creator-center {
    background: #F8F8F8;
}

.creator-center th.table-column {
    text-align: left;
}

.creator-center .container {
    display: grid;
    grid-gap: 20px;
}

.creator-center .use-income-data-box {
    display: grid;
    grid-template-columns: 420px auto;
    grid-column-gap: 20px;
}

.creator-center .statistic-box {
    display: flex;
    justify-content: space-around;
}

.creator-center .use-income-data-box .detail-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
}

.creator-center .use-income-data-box .detail-box .grey {
    color: #AAAAAA;
}

.creator-center .use-income-data-box .detail-box .black {
    color: #343434;
}

.creator-center .modify-btn {
    color: #32B6F4;
    cursor: pointer;
    margin-left: 10px;
}

.creator-center .shop-setting {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}

.creator-center .shop-setting .title {
    color: #222222;
    margin-right: 20px;
}

.creator-center .shop-setting .name p {
    padding: 10px 0;
}

.creator-center .shop-setting .poster .title {
    font-size: 16px;
    color: #222222;
}

.creator-center .shop-setting .poster .mini-image {
    width: 228px;
    height: 110px;
    border-radius: 7px;
    overflow: hidden;
    margin: 15px 0;
    background: #EEEEEE;
}

.creator-center .shop-setting .poster .large-image {
    width: 340px;
    height: 118px;
    border-radius: 7px;
    overflow: hidden;
    margin: 15px 0;
    background: #EEEEEE;
}

.creator-center .shop-setting .poster img {
    width: 100%;
}

.creator-center .shop-setting .poster .desc {
    line-height: 20px;
    color: #999999;
    font-size: 14px;
    width: 258px;
}

.creator-center .footer {
    display: grid;
    grid-template-columns: auto 578px 150px;
    align-items: end;
}

.creator-center .footer .text {
    font-size: 14px;
    color: #999999;
}