.mobile-index {
    width: 100%;
    padding: 20px;
}

.mobile-index .header {
    display: flex;
    align-items: center;
}

.mobile-index .header .logo {
    width: 50px;
    border-radius: 8px;
}

.mobile-index .header span {
    color: #fff;
    margin: 0 8px;
    font-size: 28px;
}

.mobile-index .header .version {
    width: 40px;
}

.mobile-index .body {
    text-align: center;
    padding-top: 50px;
}

.mobile-index .body .main-pic {
    width: 100%;
    margin: 20px 0;
}

.mobile-index .text {
    color: #fff;
}

.mobile-index .border {
    font-weight: bolder;
}

.mobile-index .normal {
    font-weight: 300;
}

.mobile-index .qr-code {
    width: 130px;
}

.mobile-index .qr-code + div {
    margin-top: 20px;
    margin-bottom: 60px;
}