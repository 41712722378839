.c-color-progress {
    width: 280px;
}

.c-color-progress .line {
    height: 10px;
    background: #32B6F4;
    border-radius: 5px;
}

.c-color-progress .color-tag-box {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.c-color-progress .color-tag-box .color-tag {
    border: 1px solid #EEEEEE;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.c-color-progress .color-tag-box .color-tag .color {
    width: 16px;
    height: 16px;
    border-radius: 2px;
}

.c-color-progress .color-tag-box .color-tag input {
    margin-left: 6px;
    border-radius: 2px;
    border: none;
    width: 60px;
}
