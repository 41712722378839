.c-pay-modal-box .ant-modal-content {
    border-radius: 15px;
}

.c-pay .title {
    height: 50px;
    line-height: 50px;
    background: #32B6F4;
    color: #fff;
    text-align: center;
    font-size: 20px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.c-pay .body {
    /* padding: 20px 30px; */
    text-align: center;
}

.c-pay .body img {
    width: 260px;
}

.c-pay .footer {
    padding-bottom: 20px;
    text-align: center;
}
