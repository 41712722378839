.my-cards{
    width: 1180px;
    margin: 40px auto;
}
.my-cards-list{
    display: flex!important;
    justify-content: flex-start;
    /* padding-bottom: 20px; */
    padding: 0 10px 20px;
}
.my-cards-page{
    text-align: center;
    padding-top: 20px;
}
.my-cards-page .ant-pagination-item{
    display: none;
}
.my-cards-page .ant-pagination-prev .ant-pagination-item-link,
.my-cards-page .ant-pagination-next .ant-pagination-item-link {
    border-radius: 0;
}

.my-cards-page .ant-pagination-prev:focus, 
.my-cards-page .ant-pagination-prev:hover, 
.my-cards-page .ant-pagination-next:focus,
.my-cards-page .ant-pagination-next:hover {
    color: #52bff3;
    background: #e6f8fe;
}

.my-cards .ant-carousel, .my-cards-carousel .slick-slide{
    height: auto!important;
}
.my-cards .ant-carousel, .my-cards-carousel .slick-slide{
    background: none!important;
}