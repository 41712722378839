.c-login-modal-box .ant-modal-content {
    border-radius: 15px;
}

.c-login .title {
    height: 50px;
    line-height: 50px;
    background: #32B6F4;
    color: #fff;
    text-align: center;
    font-size: 20px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.c-login .body {
    padding: 20px 30px;
}

.c-login .body .login-code {
    display: flex;
    justify-content: space-between;
}

.c-login .body .login-code input {
    background: #F5F5F5;
    border-radius: 5px;
    border: none;
    padding: 10px;
    width: 230px;
    font-size: 14px;
}

.c-login .body .login-code button {
    border-radius: 5px;
    min-width: 100px;
    width: 100px;
    font-size: 16px;
}

.c-login .body .tips {
    margin-top: 10px;
    font-size: 12px;
    color: #FC8181;
}

.c-login .body p {
    text-align: center;
    color: #333333;
}

.c-login .body img {
    width: 150px;
}
