.container {
    width: 1180px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

button.circle-btn {
    min-width: 120px;
    height: 40px;
    line-height: 40px;
    background: #32B6F4;
    border: none;
    color: #fff;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 999px;
}

button.base-btn {
    height: 40px;
    line-height: 40px;
    background: #32B6F4;
    border: none;
    color: #fff;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    min-width: 100px;
    font-size: 16px;
}

button.cancel {
    background: #EEEEEE;
    color: #ABABAB;
}

button.blue {
    background: #32B6F4;
}

button.red {
    background: #FC8181;
}

button.white {
    background: #fff;
    color: #32B6F4;
    border: 1px solid #32B6F4;
}

button.mini-btn {
    width: 86px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #EEEEEE;
    color: #32B6F4;
}

table th.table-column {
    text-align: center;
    background: #F9FDFF;
}

.c-login-modal-box .ant-modal-content {
    border-radius: 15px;
}

.c-login .title {
    height: 50px;
    line-height: 50px;
    background: #32B6F4;
    color: #fff;
    text-align: center;
    font-size: 20px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.c-login .body {
    padding: 20px 30px;
}

.c-login .body .login-code {
    display: flex;
    justify-content: space-between;
}

.c-login .body .login-code input {
    background: #F5F5F5;
    border-radius: 5px;
    border: none;
    padding: 10px;
    width: 230px;
    font-size: 14px;
}

.c-login .body .login-code button {
    border-radius: 5px;
    min-width: 100px;
    width: 100px;
    font-size: 16px;
}

.c-login .body .tips {
    margin-top: 10px;
    font-size: 12px;
    color: #FC8181;
}

.c-login .body p {
    text-align: center;
    color: #333333;
}

.c-login .body img {
    width: 150px;
}

a,
a:hover {
    text-decoration: none !important;
}

header.c-header {
    height: 80px;
    position: relative;
}

header.c-header img {
    height: 44px;
    margin-left: -15px;
    border-radius: 8px;
}

header.c-header .navbar-header span {
    margin-left: 10px;
    font-size: 20px;
    line-height: 80px;
    color: #32b6f4;
    font-weight: bold;
}

header.c-header ul {
    line-height: 80px;
}

header.c-header ul li {
    width: 110px;
    text-align: center;
    font-size: 15px;
}

header.c-header ul li span {
    line-height: 50px;
    padding: 8px;
}

header.c-header ul li.active span {
    color: #32b6f4;
    border-bottom: 2px solid #32b6f4;
}
header.c-header .navbar-right {
    margin-right: -32px;
}
.navbar-login {
    background: #32b6f4;
    color: #fff;
    cursor: pointer;
    border-radius: 6px;
    padding: 14px 32px;
    /* box-shadow: 2px 2px 4px #999; */
}
.c-header .sub-nav {
    display: flex;
    position: absolute;
    top: 80px;
    right: 20px;
    list-style: none;
    padding: 30px 0;
    background: #fff;
    line-height: normal;
    z-index: 99;
    box-shadow: 0 3px 7px 0 rgb(0,0,0, .1);
}
.c-header .sub-nav li{
    cursor: pointer;
}
.c-header .sub-nav li:not(:first-child){
    border-left: 1px solid #eee;
}
header.c-header .sub-nav li span {
    line-height: normal;
    padding: 4px 12px;
    border-radius: 4px;
}
header.c-header .sub-nav li span:hover,
.sub-nav-li_active{
    background: #40a9ff;
    color: #fff;
}

footer.c-footer {
    padding-bottom: 50px;
}

footer.c-footer .row.first {
    margin-left: 0;
    margin-right: 0;
    margin-top: 100px;
    text-align: center;
}

footer.c-footer .row img {
    width: 140px;
}

footer.c-footer .row.guanlian {
    margin-top: 10px;
    border-top: 1px solid #ddd;
    padding-top: 10px;
    padding-left: -10px;
    padding-right: -10px;
}

footer.c-footer .row.guanlian span {
    color: #bbb;
    border-right: 1px solid #ccc;
    font-size: 12px;
    padding-left: 6px;
    padding-right: 6px;
}
.home section .banner {
    height: 540px;
    background: url(https://xiaoka-1254962201.cos.ap-shanghai.myqcloud.com/%E5%A4%B4%E5%9B%BE.png);
    background-position: center;
}

.home section .banner img {
    width: 100%;
    height: 100%;
}

.home section .container .row .font {
    font-size: 38px;
}

.home section .container .row .bold {
    font-weight: bold;
}

.home section .container .row.three-part .img {
    margin-top: 150px;
    margin-bottom: 30px;
    text-align: center;
}

.home section .container .row.three-part h3 {
    font-size: 16px;
    font-weight: bold;
    margin-left: 20px;
    margin-right: 20px;
}

.home section .container .row.three-part p {
    font-size: 12px;
    margin-left: 20px;
    margin-right: 20px;
}

.online {
    background: #F8F8F8;
}

.online font {
    margin-left: 5px;
    margin-right: 5px;
}

.online .section {
    margin: 50px 150px;
    padding: 24px;
    padding-bottom: 30px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 8px 14px 0 rgba(185,188,189,0.21);
}

.online .section .header {
    border-bottom: 1px solid #eee;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.online .section .header .package-name {
    line-height: 32px;
    color: #b5b5b5;
}

.online .section .header img {
    width: 20px;
    height: 22px;
}

.online .section .question {
    color: #333;
}

.online .section .option-box {
    margin: 0 auto;
    margin-top: 250px;
    width: 400px;
    display: flex;
    justify-content: space-between;
}

.online .section .next-box {
    margin-top: 100px;
    text-align: center;
}

.online .section .correct-line {
    margin: 50px 0;
}

.online .section .empty {
    padding-top: 80px;
    padding-bottom: 80px;
}

.online .tips {
    margin: 50px 150px;
    font-size: 14px;
}

.online .blue-font {
    color: #42AFE2;
}
/* components/correct-line/correct-line.wxss */
.c-correct-line .complete {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0;
  font-size: 24px;
}

.c-correct-line .complete.green {
  color: #52D0A0;
}

.c-correct-line .complete.red {
  color: #FC8181;
}

.c-correct-line .complete .line {
  flex-grow: 2;
  height: 1px;
  background: #aaa;
}

.c-correct-line .complete.green .line {
  background: #d6fced;
}

.c-correct-line .complete.red .line {
  background: #fde4e4;
}

.c-correct-line .complete .text {
  margin: auto 8px;
}

.c-correct-line .complete img {
  margin: 0 10px;
  width: 30px;
}
.custom .image {
    width: 100%;
    height: 311px;
    background: url(https://cloud-minapp-16465.cloud.ifanrusercontent.com/1j6xKwpdfIXAUuQa.png);
    background-size: 100%;
}

.custom .image .title {
    margin-top: 60px;
    margin-bottom: 12px;
    color: #fff;
    font-size: 36px;
    font-weight: bold;
}

.custom .image .text {
    width: 460px;
    color: #fff;
    font-size: 12px;
    line-height: 1.5;
}

.custom .image button {
    margin: 10px 0;
    min-width: 110px;
    padding: 8px 20px;
    background: linear-gradient(#46D9FE, #33A8DE);
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
}

.custom .member {
    /* width: 1200px; */
    /* margin: 0 auto; */
    /* padding: 50px 0; */
    display: flex;
    justify-content: space-between;
    background: rgb(246,246,246);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 15px rgb(236,236,236) solid;
    padding-bottom: 50px;
}
.member-title{
    margin: 40px 0;
}
.member-cards{
    display: flex;
    width: 930px;
    justify-content: space-between;
}

.custom .bag {
    background: #F8F8F8;
    padding: 50px 0;
}

.custom .bag .title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
}

.custom .bag .desc {
    text-align: center;
}

.custom .bag .cards {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
.c-upload-modal-box .ant-modal-content {
    border-radius: 15px;
}

.c-upload .title {
    height: 50px;
    line-height: 50px;
    background: #32B6F4;
    color: #fff;
    text-align: center;
    font-size: 20px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.c-upload .body {
    padding: 20px 30px;
}

.c-upload .body p {
    text-align: center;
    color: #333333;
}

.c-upload .footer {
    padding-bottom: 20px;
    text-align: center;
}

.c-pay-modal-box .ant-modal-content {
    border-radius: 15px;
}

.c-pay .title {
    height: 50px;
    line-height: 50px;
    background: #32B6F4;
    color: #fff;
    text-align: center;
    font-size: 20px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.c-pay .body {
    /* padding: 20px 30px; */
    text-align: center;
}

.c-pay .body img {
    width: 260px;
}

.c-pay .footer {
    padding-bottom: 20px;
    text-align: center;
}

.c-card {
    width: 280px;
    padding: 20px;
    background: #fff;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 8px 14px 0 rgba(185,188,189,0.31);
}

.c-card .title {
    font-size: 18px;
    font-weight: bold;
    line-height: 32px;
}

.c-card .text {
    font-size: 14px;
    line-height: 1.5;
}

.c-card .btns {
    margin-top: 30px;
    margin-bottom: 10px;
}

.c-card .btns button {
    margin: 0 10px;
    min-width: 110px;
    padding: 8px 20px;
    background: linear-gradient(#46D9FE, #33A8DE);
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
}

.c-card .btns button.grey {
    background: linear-gradient(#eee, #aaa);
    color: #fff;
}

.c-card .grey {
    color: #999;
}

.c-card .bold {
    font-weight: bold;
}

.c-cards {
    width: 280px;
    padding: 15px;
    background: #fff;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 8px 14px 0 rgba(185,188,189,0.31);
    position: relative;
    height: 470px;
    margin: 10px;
}
.c-cards:nth-child(even){
    margin-top: 65px;
}
.c-card-img{
    background-size: cover!important;
    width: 100%;
    height: 130px;
    border-radius: 8px;
    background: linear-gradient(to bottom right, #D5DEED, #BDCBDE, #A4B7CF);
}
.private-bg, .private-content{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.private-content{
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.private-title, .private-updatetime, .percent-content{
    color: #fff;
}
.percent-top{
    border-bottom: 1px solid #fff;
    font-size:20px;
    padding-bottom: 5px;
    width: 100px;
    margin: 0 auto 5px;
}
.percent-bottom{
    font-size:12px;
}
.private-btn{
    font-size: 16px;
    border: none;
    padding: 10px 30px;
    width: auto;
    height: auto;
    color: #52bff3;
    margin-bottom: 20px;
    display: inline-block;
    background: #fff;
    border-radius: 5px;
}
.chapters-container{
    display: flex;
    flex-direction: column;
    color: #52bff3;
    padding: 0;
    height: 255px;
    overflow-y: auto;
}
.chapters-item{
    display: unset;
    padding: 8px 15px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.chapters-item:nth-child(odd){
    background: #f3fcff;
}
.chapters-item:nth-child(even){
    background: #e6f8fe;
}
.bottom-btn{
    display: flex;
    justify-content: space-between;
}
.bottom-btn button{
    width: auto;
    height: auto;
    border: none;
    background: #f6f6f6;
    color: #aaa;
    padding: 10px;
}
.bottom-btn button:focus, .bottom-btn button:active, .bottom-btn button:hover{
    color: #52bff3;
    border: 1px solid #52bff3;
    background: #e6f8fe;
}
.chapters-active{
    color: #fff;
    background: #52bff3;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    padding-top: 2px;
    font-size: 12px;
}
.chapters-inactive{
    width: 16px;
    height: 16px;
    border: 1px solid #52bff3;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
}
.empty-link{
    width: 100%;
    height: 100%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}
.empty-title-child{
    font-size: 16px;
    font-weight: normal;
    padding-top: 5px;
}
.empty-add{
    font-style: normal;
    font-size: 60px;
    color: #fff;
    margin-bottom: 100px;
}
.my-cards{
    width: 1180px;
    margin: 40px auto;
}
.my-cards-list{
    display: flex!important;
    justify-content: flex-start;
    /* padding-bottom: 20px; */
    padding: 0 10px 20px;
}
.my-cards-page{
    text-align: center;
    padding-top: 20px;
}
.my-cards-page .ant-pagination-item{
    display: none;
}
.my-cards-page .ant-pagination-prev .ant-pagination-item-link,
.my-cards-page .ant-pagination-next .ant-pagination-item-link {
    border-radius: 0;
}

.my-cards-page .ant-pagination-prev:focus, 
.my-cards-page .ant-pagination-prev:hover, 
.my-cards-page .ant-pagination-next:focus,
.my-cards-page .ant-pagination-next:hover {
    color: #52bff3;
    background: #e6f8fe;
}

.my-cards .ant-carousel, .my-cards-carousel .slick-slide{
    height: auto!important;
}
.my-cards .ant-carousel, .my-cards-carousel .slick-slide{
    background: none!important;
}
.s-card {
  background: #fff;
  border-radius: 10px;
  height: 180px;
}
.s-card-img {
  width: 100%;
  height: 110px;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  background: linear-gradient(to bottom right, #D5DEED, #BDCBDE, #A4B7CF);
}
.s-card-img img {
  width: 100%;
  height: 100%;
}

.s-card-info {
  padding: 15px;
}
.s-card-info .name {
  font-size: 14px;
  text-align: left;
  color: #222;
}
.s-card-info .flex {
  display: flex;
  justify-content: space-between;
}
.s-card-info .flex .num{
  font-size: 12px;
  color: #999;
}
.s-card-info .flex .price {
  font-size: 12px;
  color: #FC8181;
}
.m-card {
  width: 242px;
  border-radius: 8px;
  margin-bottom: 20px;
  color: #fff;
  background: linear-gradient(to bottom right, #D5DEED, #BDCBDE, #A4B7CF);
}
.m-card-info {
  padding: 22px 16px;
  cursor: pointer;
  min-height: 100px;
}

.m-card-info h5 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.m-card-info p {
  font-size: 13px;
  font-weight: 400;
}

.m-card .ant-radio-group {
  width: 100%;
  padding: 6px 16px;
  background: rgba(0,0,0,0.1);
  border-radius: 0 0 8px 8px;
}

.m-card .ant-radio-wrapper {
  color: #fff;
}
.shop {
  background: #f6f6f6;
  padding: 20px 0;
}
.shop .container {
  padding: 0;
  display: flex;
}
.ant-carousel {
  height: 305px;
  background: #364d79;
  border-radius: 6px;
}
.ant-carousel .slick-slide {
  text-align: center;
  height: 305px;
  background: #364d79;
  overflow: hidden;
  border-radius: 6px;
  cursor: pointer;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}
.shop-left {
  width: 886px;
}
.shop-left img {
  width: 100%;
}
.shop-my {
  width: 290px;
  margin-left: 20px;
  background-color: #fff;
  border-radius: 12px;
}
.shop-my-title {
  font-size: 18px;
  font-weight: 600;
  padding: 20px 24px;
  border-bottom: 1px solid #eee;
}
.shop-my-list {
  padding: 20px 24px;
}
.shop .card-type {
  display: flex;
  justify-content: space-between;
  margin: 30px 0 18px 0;
  height: 28px;
  line-height: 28px;
  
}
.shop .card-type .title {
  font-size: 24px;
  font-weight: 600;
  color: #222;
}
.shop .card-type .more {
  font-size: 14px;
  color: #999;
  cursor: pointer;
}

.shop .s-card {
  margin-bottom: 15px;
}

.mobile-index {
    width: 100%;
    padding: 20px;
}

.mobile-index .header {
    display: flex;
    align-items: center;
}

.mobile-index .header .logo {
    width: 50px;
    border-radius: 8px;
}

.mobile-index .header span {
    color: #fff;
    margin: 0 8px;
    font-size: 28px;
}

.mobile-index .header .version {
    width: 40px;
}

.mobile-index .body {
    text-align: center;
    padding-top: 50px;
}

.mobile-index .body .main-pic {
    width: 100%;
    margin: 20px 0;
}

.mobile-index .text {
    color: #fff;
}

.mobile-index .border {
    font-weight: bolder;
}

.mobile-index .normal {
    font-weight: 300;
}

.mobile-index .qr-code {
    width: 130px;
}

.mobile-index .qr-code + div {
    margin-top: 20px;
    margin-bottom: 60px;
}
.card-edit {
    background: #F8F8F8;
}

.card-edit .container {
    padding-top: 20px;
}

.card-edit .query-box {
    padding-top: 20px;
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: 500px auto 120px 120px;
}

.card-edit .table-column {
    background: #fff;
}

.card-edit th.table-column {
    text-align: center;
    background: #F9FDFF;
}

.card-edit .ant-table-footer {
    background: #fff;
}

.card-edit .table-footer {
    display: grid;
    grid-template-columns: auto 610px 300px;
    justify-items: right;
    align-items: center;
    color: #9B9B9B;
    padding: 10px 0;
}

.card-edit .table-footer button {
    background: #EBF7FD;
    border: 1px solid #5BC1EC;
    border-radius: 4px;
    color: #5BC1EC;
    padding: 10px 20px;
}

.card-edit .table-footer button.creator-center-btn {
    background: #5BC1EC;
    color: #fff;
    margin-left: 10px;
}

.modal-bag {
    border-bottom: 1px solid #EEEEEE;;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
    margin-top: -10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.modal-card {
    display: grid;
    grid-template-columns: 420px 180px;
}

.modal-mini-program-card {
    padding-top: 20px;
}

.ccc {
    background: red;
}

.c-cute-modal-box .ant-modal-content {
    border-radius: 15px;
}

.c-cute-modal .body {
    padding: 20px;
}

.c-cute-modal .body .content {
    text-align: center;
    margin: 20px;
}

.c-cute-modal .body .input-box {
    border: 1px solid #32B6F4;
    border-radius: 4px;
    padding: 8px;
    display: grid;
    grid-template-columns: 75% 25%;
}

.c-cute-modal .body .input-box input {
    border: none;
    outline: none;
    width: 100%;
}

.c-cute-modal-box .body .input-box .with-end {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

.c-cute-modal .footer {
    padding: 20px;
    padding-top: 0;
    display: flex;
    justify-content: space-between;
}

.c-custom-input {
    display: flex;
    /* align-items: center; */
    padding: 10px 0;
}

.c-custom-input .title {
    min-width: 100px;
    text-align: right;
    color: #333333;
    margin-right: 15px;
    font-size: 14px;
    line-height: 38px;
}

.c-custom-input .input-box {
    display: flex;
    align-items: center;
}

.c-custom-input .input-box input {
    min-width: 280px;
    background: #F8F8F8;
    border: 1px solid #EEEEEE;
    border-radius: 4px;
    padding: 8px;
}

.c-custom-input .input-box .required {
    color: #FC8181;
    margin-left: 6px;
}

.c-custom-input .input-box .add-sub {
    width: 20px;
    height: 20px;
    line-height: 15px;
    text-align: center;
    font-size: 24px;
    font-weight: 200;
    border: 1px solid #6C6C6C;
    border-radius: 100%;
    color: #6C6C6C;
    margin-left: 10px;
    cursor: pointer;
}
.c-custom-dropdown {
    display: flex;
    padding: 10px 0;
}

.c-custom-dropdown .title {
    min-width: 100px;
    text-align: right;
    color: #333333;
    margin-right: 15px;
    font-size: 14px;
    line-height: 38px;
}

.c-custom-dropdown .content {
    min-width: 180px;
    background: #F8F8F8;
    border: 1px solid #EEEEEE;
    border-radius: 4px;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.c-custom-dropdown .required {
    color: #FC8181;
    margin-left: 6px;
}

.c-mini-program-card {
    margin-top: -10px;
    width: 260px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 7px 0 rgba(185,188,189,0.4);
    min-height: 340px;
}

.c-mini-program-card .header {
    color: #B6B6B6;
    line-height: 2;
    border-bottom: 1px solid #EEEEEE;
    margin-bottom: 10px;
    min-height: 28px;
    display: flex;
    justify-content: space-between;
}

.c-mini-program-card .header .left span {
    margin-right: 4px;
}

.c-mini-program-card .header img {
    width: 20px;
    height: 20px;
    margin: 4px;
}

.c-custom-modal-box .ant-modal-content {
    border-radius: 15px;
}

.c-custom-modal-title {
    height: 50px;
    line-height: 50px;
    background: #32B6F4;
    color: #fff;
    text-align: center;
    font-size: 20px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.c-custom-modal-body {
    padding: 20px;
}

.c-custom-modal-footer {
    border-top: 1px solid #EEEEEE;
    text-align: right;
    padding: 20px;
}

.c-custom-modal-footer button {
    margin-left: 20px;
}

.detail {
  background: #F8F8F8;
  padding-bottom: 20px;
}
.detail .bg {
  background: #fff;
  border-radius: 12px;
  padding: 60px 100px;
}

.detail-summary {
  display: flex;
}

.detail-img {
  position: relative;
  width: 300px;
  height: 214px;
  margin-right: 32px;
  border-radius: 8px;
  flex-shrink: 0;
}

.detail-img .name {
  position: absolute;
  top: 30px;
  left: 24px;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
}
.detail-img .devider {
  position: absolute;
  left: 24px;
  top: 80px;
  width: 54px;
  height: 3px;
  background-color: #fff;
}
.detail-img .teacher {
  position: absolute;
  right: 24px;
  bottom: 30px;
  font-size: 16px;
  color: #fff;
}

.detail-img .teacher img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 4px;
}

.summary-item {
  color: #666;
  margin-bottom: 12px;
}

.summary-item span {
  color: #222;
}

.detail .detail-title {
  width: 100%;
  padding: 6px 10px;
  background: #F8F8F8;
  text-align: left;
  margin: 14px 0;
}


.detail-cata {
  display: flex;
  flex-wrap: wrap;
}
.detail .cata-item {
  display: flex;
  width: 30%;
  margin-right: 40px;
  position: relative;
  overflow: hidden;
}
.detail .cata-item-name {
  flex-shrink: 0;
  padding-right: 4px;
}

.detail .cata-item-total {
  position: absolute;
  right: 0;
  background: #fff;
  padding-left: 4px;
}

.detail-cont {
  padding: 20px;
  background: #F8F8F8;
}

.ellipsis-4 {
  height: 86px;
  overflow:hidden;
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-line-clamp: 4; /* 想要的行数*/
  -webkit-box-orient:vertical;

}

.detail .blue-font {
  color: #32B6F4;
}
.detail .red-font {
  color: #FC8181;
}

.detail-buy {
  position: fixed;
  width: 100%;
  height: 80px;
  line-height: 80px;
  background: #fff;
  bottom: 0;
}

.detail-buy .container {
  display: flex;
  justify-content: flex-end;
}

.detail-buy .exchange {
  color: #32B6F4;
  margin-right: 74px;
  cursor: pointer;
  text-decoration:underline;
}
.detail-buy .buy {
  width: 160px;
  height: 44px;
  text-align: center;
  line-height: 44px;
  margin: 18px 0;
  background: #32B6F4;
  color: #fff;
  margin-left: 30px;
  border-radius: 8px;
  cursor: pointer;
}
.detail-buy .disable {
  opacity: .5;
  cursor: auto;
}

.exchange-modal .ant-modal-footer  {
  border-top: none;
}
.exchange-modal .ant-modal-footer .ant-btn{
  width: 48% ;
  background-color: #eee;
  border-color: #eee;
}
.exchange-modal .ant-modal-footer .ant-btn-primary{
  background: #32B6F4 100%;
  border-color: #32B6F4 !important;
}
.exchange-modal .ant-form-item{
  margin-bottom: 0;
}
.exchange-modal .ant-modal-header {
  border-bottom: none;
}
.exchange-modal .ant-modal-body {
  padding: 0 24px;
}
.bag-setting {
    background: #F8F8F8;
}

.bag-setting .container {
    padding-top: 20px;
}

.bag-setting .box {
    border: 1px solid #EEEEEE;
    background: #fff;
    padding: 20px 80px;
    margin-top: 20px;
    margin-bottom: 30px;
    position: relative;
}

.bag-setting .box .title-box {
    padding-bottom: 20px;
    border-bottom: 1px solid #EEEEEE;
    display: flex;
    justify-self: start;
    align-items: center;
}

.bag-setting .box .title-box .blue-line {
    width: 6px;
    height: 28px;
    border-radius: 3px;
    background: #32B6F4;
    margin-right: 10px;
}

.bag-setting .box .title-box .title {
    margin: 0;
}

.bag-setting .box .body-box {
    display: grid;
    grid-template-columns: 500px 150px 300px;
    border-bottom: 1px solid #EEEEEE;
    padding: 20px 0;
}

.bag-setting .box .body-box .bag-description {
    width: 280px;
    min-height: 100px;
    background: #F8F8F8;
    border: 1px solid #EEEEEE;
    border-radius: 4px;
    padding: 8px;
}

.bag-setting .box .body-box .random-color-btn {
    color: #32B6F4;
    cursor: pointer;
    margin-top: 10px;
}

.bag-setting .box .middle {
    display: flex;
    align-items: center;
}

.bag-setting .box .btn-box {
    margin-left: 115px;
    margin-top: 30px;
}

.bag-setting .box .btn-box .cancel {
    margin-right: 30px;
}

.bag-setting .upload-download-btns{
    position: absolute;
    top: 18px;
    right: 0;
}
.bag-setting .upload-download-btns button{
    margin: 0 10px;
    min-width: 110px;
    background: linear-gradient(#46D9FE, #33A8DE);
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    padding: 0 20px;
}
.c-bag-card {
    width: 280px;
    height: 140px;
    border-radius: 10px;
    padding: 20px;
    color: #fff;
}

.c-bag-card .title {
    font-size: 24px;
}

.c-bag-card .author {
    font-size: 14px;
}
.c-mini-program-package-page {
    width: 280px;
    min-height: 400px;
    border-radius: 8px;
    box-shadow: 0 4px 7px 0 rgba(185,188,189,0.4);
}

.c-mini-program-package-page .header {
    height: 140px;
    border-radius: 8px;
    background: #3CB7F2;
    color: #fff;
    padding: 10px 10px;
    display: grid;
    grid-template-columns: 55px auto;
}

.c-mini-program-package-page .avatar {
    width: 45px;
    border-radius: 100%;
}

.c-mini-program-package-page .author {
    font-size: 16px;
    font-weight: bold;
}

.c-mini-program-package-page .title {
    font-size: 14px;
}

.c-mini-program-package-page .desc {
    font-size: 12px;
}

.c-mini-program-package-page .desc.webkit {
    width: 250px;
    -webkit-transform: scale(0.85);
    -webkit-transform-origin: top left;
            transform-origin: top left;
}

.c-mini-program-package-page .text-line {
    padding: 10px;
    padding-bottom: 0;
    font-size: 12px;
    color: #B6B6B6;
    display: flex;
    justify-content: space-between;
}

.c-mini-program-package-page .sub-bag-box {
    display: grid;
    grid-template-columns: 50% 50%;
}

.c-mini-program-package-page .sub-bag-box .sub-bag {
    border: 1px solid #EEEEEE;
    border-radius: 10px;
    margin: 10px;
    padding: 10px
}

.c-mini-program-package-page .btn-box {
    margin: 0!important;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px;
}

.c-color-progress {
    width: 280px;
}

.c-color-progress .line {
    height: 10px;
    background: #32B6F4;
    border-radius: 5px;
}

.c-color-progress .color-tag-box {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.c-color-progress .color-tag-box .color-tag {
    border: 1px solid #EEEEEE;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.c-color-progress .color-tag-box .color-tag .color {
    width: 16px;
    height: 16px;
    border-radius: 2px;
}

.c-color-progress .color-tag-box .color-tag input {
    margin-left: 6px;
    border-radius: 2px;
    border: none;
    width: 60px;
}

.creator-center {
    background: #F8F8F8;
}

.creator-center th.table-column {
    text-align: left;
}

.creator-center .container {
    display: grid;
    grid-gap: 20px;
}

.creator-center .use-income-data-box {
    display: grid;
    grid-template-columns: 420px auto;
    grid-column-gap: 20px;
}

.creator-center .statistic-box {
    display: flex;
    justify-content: space-around;
}

.creator-center .use-income-data-box .detail-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
}

.creator-center .use-income-data-box .detail-box .grey {
    color: #AAAAAA;
}

.creator-center .use-income-data-box .detail-box .black {
    color: #343434;
}

.creator-center .modify-btn {
    color: #32B6F4;
    cursor: pointer;
    margin-left: 10px;
}

.creator-center .shop-setting {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}

.creator-center .shop-setting .title {
    color: #222222;
    margin-right: 20px;
}

.creator-center .shop-setting .name p {
    padding: 10px 0;
}

.creator-center .shop-setting .poster .title {
    font-size: 16px;
    color: #222222;
}

.creator-center .shop-setting .poster .mini-image {
    width: 228px;
    height: 110px;
    border-radius: 7px;
    overflow: hidden;
    margin: 15px 0;
    background: #EEEEEE;
}

.creator-center .shop-setting .poster .large-image {
    width: 340px;
    height: 118px;
    border-radius: 7px;
    overflow: hidden;
    margin: 15px 0;
    background: #EEEEEE;
}

.creator-center .shop-setting .poster img {
    width: 100%;
}

.creator-center .shop-setting .poster .desc {
    line-height: 20px;
    color: #999999;
    font-size: 14px;
    width: 258px;
}

.creator-center .footer {
    display: grid;
    grid-template-columns: auto 578px 150px;
    align-items: end;
}

.creator-center .footer .text {
    font-size: 14px;
    color: #999999;
}
.c-container {
    background: #fff;
    border-radius: 10px;
}

.c-container>div {
    padding: 18px;
}

.c-container-title-box {
    border-bottom: 1px solid #EEEEEE;
}

.c-container-title {
    color: #222222;
    font-size: 18px;
    font-weight: bold;
    margin-right: 20px;
}

.c-container-desc {
    font-size: 14px;
    color: #999999;
}

.mobile-shop-tips {
    margin-top: 50px;
    text-align: center;
    color: #333333;
}

.mobile-shop {
    padding: 30px;
    background-image: linear-gradient(to bottom, #FFFFFF, #E9F6FC);
}

.mobile-shop .title {
    margin: 0 60px;
    margin-top: 140px;
    margin-bottom: 20px;
}

.mobile-shop .title img {
    width: 100%;
}

.mobile-shop .select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 12px;
    font-size: 16px;
    background-image: linear-gradient(to right, #5FB8F1, #92E0FC);
    color: #fff;
    margin-bottom: 14px;
}

.mobile-shop .select .select-btn {
    width: 24px;
}

.mobile-shop .select-box {
    height: 0;
    overflow: hidden;
    transition: all .5s;
}

.mobile-shop .select-box.show {
    margin-bottom: 20px;
    height: auto;
}

.mobile-shop .triangle-up {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
    margin-left: 300px;
}

.mobile-shop .select-detail {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 0 14px;
}

.mobile-shop .select-detail .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #333333;
    line-height: 3;
    border-bottom: 1px solid #F7F7F7;
}

.mobile-shop .select-detail .item img {
    width: 16px;
}

.mobile-shop .bag-box {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
}

.mobile-shop .bag-box img {
    width: 100%;
    border-radius: 6px;
}

.mobile-shop .bag-box .item {
    position: relative;
}

.mobile-shop .bag-box .item .tag {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50px;
    text-align: center;
    line-height: 20px;
    border-radius: 6px 0;
    color: #fff;
    font-size: 12px;
    background: #3BAEE4;
}

.mobile-shop-modal-box .ant-modal-content {
    border-radius: 12px;
    overflow: hidden;
}

.mobile-shop-modal .cover {
    width: 100%;
}

.mobile-shop-modal .text-box {
    padding: 20px;
}

.mobile-shop-modal .text-box .title {
    color: #333333;
}

.mobile-shop-modal .text-box .introduction {
    font-size: 12px;
    color: #333333;
}

.mobile-shop-modal .text-box .detail-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    margin-bottom: 20px;
}

.mobile-shop-modal .text-box .buy {
    width: 100%;
    height: 50px;
    font-size: 16px;
}

.mobile-shop-modal .text-box .buy.grey {
    background: #EEEEEE;
    color: #AEAEAE;
}

.mobile-shop-modal-rq-code {
    width: 100%;
}

