.s-card {
  background: #fff;
  border-radius: 10px;
  height: 180px;
}
.s-card-img {
  width: 100%;
  height: 110px;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  background: linear-gradient(to bottom right, #D5DEED, #BDCBDE, #A4B7CF);
}
.s-card-img img {
  width: 100%;
  height: 100%;
}

.s-card-info {
  padding: 15px;
}
.s-card-info .name {
  font-size: 14px;
  text-align: left;
  color: #222;
}
.s-card-info .flex {
  display: flex;
  justify-content: space-between;
}
.s-card-info .flex .num{
  font-size: 12px;
  color: #999;
}
.s-card-info .flex .price {
  font-size: 12px;
  color: #FC8181;
}