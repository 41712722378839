.custom .image {
    width: 100%;
    height: 311px;
    background: url(https://cloud-minapp-16465.cloud.ifanrusercontent.com/1j6xKwpdfIXAUuQa.png);
    background-size: 100%;
}

.custom .image .title {
    margin-top: 60px;
    margin-bottom: 12px;
    color: #fff;
    font-size: 36px;
    font-weight: bold;
}

.custom .image .text {
    width: 460px;
    color: #fff;
    font-size: 12px;
    line-height: 1.5;
}

.custom .image button {
    margin: 10px 0;
    min-width: 110px;
    padding: 8px 20px;
    background: linear-gradient(#46D9FE, #33A8DE);
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
}

.custom .member {
    /* width: 1200px; */
    /* margin: 0 auto; */
    /* padding: 50px 0; */
    display: flex;
    justify-content: space-between;
    background: rgb(246,246,246);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 15px rgb(236,236,236) solid;
    padding-bottom: 50px;
}
.member-title{
    margin: 40px 0;
}
.member-cards{
    display: flex;
    width: 930px;
    justify-content: space-between;
}

.custom .bag {
    background: #F8F8F8;
    padding: 50px 0;
}

.custom .bag .title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
}

.custom .bag .desc {
    text-align: center;
}

.custom .bag .cards {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}