.detail {
  background: #F8F8F8;
  padding-bottom: 20px;
}
.detail .bg {
  background: #fff;
  border-radius: 12px;
  padding: 60px 100px;
}

.detail-summary {
  display: flex;
}

.detail-img {
  position: relative;
  width: 300px;
  height: 214px;
  margin-right: 32px;
  border-radius: 8px;
  flex-shrink: 0;
}

.detail-img .name {
  position: absolute;
  top: 30px;
  left: 24px;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
}
.detail-img .devider {
  position: absolute;
  left: 24px;
  top: 80px;
  width: 54px;
  height: 3px;
  background-color: #fff;
}
.detail-img .teacher {
  position: absolute;
  right: 24px;
  bottom: 30px;
  font-size: 16px;
  color: #fff;
}

.detail-img .teacher img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 4px;
}

.summary-item {
  color: #666;
  margin-bottom: 12px;
}

.summary-item span {
  color: #222;
}

.detail .detail-title {
  width: 100%;
  padding: 6px 10px;
  background: #F8F8F8;
  text-align: left;
  margin: 14px 0;
}


.detail-cata {
  display: flex;
  flex-wrap: wrap;
}
.detail .cata-item {
  display: flex;
  width: 30%;
  margin-right: 40px;
  position: relative;
  overflow: hidden;
}
.detail .cata-item-name {
  flex-shrink: 0;
  padding-right: 4px;
}

.detail .cata-item-total {
  position: absolute;
  right: 0;
  background: #fff;
  padding-left: 4px;
}

.detail-cont {
  padding: 20px;
  background: #F8F8F8;
}

.ellipsis-4 {
  height: 86px;
  overflow:hidden;
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-line-clamp: 4; /* 想要的行数*/
  -webkit-box-orient:vertical;

}

.detail .blue-font {
  color: #32B6F4;
}
.detail .red-font {
  color: #FC8181;
}

.detail-buy {
  position: fixed;
  width: 100%;
  height: 80px;
  line-height: 80px;
  background: #fff;
  bottom: 0;
}

.detail-buy .container {
  display: flex;
  justify-content: flex-end;
}

.detail-buy .exchange {
  color: #32B6F4;
  margin-right: 74px;
  cursor: pointer;
  text-decoration:underline;
}
.detail-buy .buy {
  width: 160px;
  height: 44px;
  text-align: center;
  line-height: 44px;
  margin: 18px 0;
  background: #32B6F4;
  color: #fff;
  margin-left: 30px;
  border-radius: 8px;
  cursor: pointer;
}
.detail-buy .disable {
  opacity: .5;
  cursor: auto;
}

.exchange-modal .ant-modal-footer  {
  border-top: none;
}
.exchange-modal .ant-modal-footer .ant-btn{
  width: 48% ;
  background-color: #eee;
  border-color: #eee;
}
.exchange-modal .ant-modal-footer .ant-btn-primary{
  background: #32B6F4 100%;
  border-color: #32B6F4 !important;
}
.exchange-modal .ant-form-item{
  margin-bottom: 0;
}
.exchange-modal .ant-modal-header {
  border-bottom: none;
}
.exchange-modal .ant-modal-body {
  padding: 0 24px;
}