.c-bag-card {
    width: 280px;
    height: 140px;
    border-radius: 10px;
    padding: 20px;
    color: #fff;
}

.c-bag-card .title {
    font-size: 24px;
}

.c-bag-card .author {
    font-size: 14px;
}