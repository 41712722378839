
footer.c-footer {
    padding-bottom: 50px;
}

footer.c-footer .row.first {
    margin-left: 0;
    margin-right: 0;
    margin-top: 100px;
    text-align: center;
}

footer.c-footer .row img {
    width: 140px;
}

footer.c-footer .row.guanlian {
    margin-top: 10px;
    border-top: 1px solid #ddd;
    padding-top: 10px;
    padding-left: -10px;
    padding-right: -10px;
}

footer.c-footer .row.guanlian span {
    color: #bbb;
    border-right: 1px solid #ccc;
    font-size: 12px;
    padding-left: 6px;
    padding-right: 6px;
}