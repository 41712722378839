.shop {
  background: #f6f6f6;
  padding: 20px 0;
}
.shop .container {
  padding: 0;
  display: flex;
}
.ant-carousel {
  height: 305px;
  background: #364d79;
  border-radius: 6px;
}
.ant-carousel .slick-slide {
  text-align: center;
  height: 305px;
  background: #364d79;
  overflow: hidden;
  border-radius: 6px;
  cursor: pointer;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}
.shop-left {
  width: 886px;
}
.shop-left img {
  width: 100%;
}
.shop-my {
  width: 290px;
  margin-left: 20px;
  background-color: #fff;
  border-radius: 12px;
}
.shop-my-title {
  font-size: 18px;
  font-weight: 600;
  padding: 20px 24px;
  border-bottom: 1px solid #eee;
}
.shop-my-list {
  padding: 20px 24px;
}
.shop .card-type {
  display: flex;
  justify-content: space-between;
  margin: 30px 0 18px 0;
  height: 28px;
  line-height: 28px;
  
}
.shop .card-type .title {
  font-size: 24px;
  font-weight: 600;
  color: #222;
}
.shop .card-type .more {
  font-size: 14px;
  color: #999;
  cursor: pointer;
}

.shop .s-card {
  margin-bottom: 15px;
}
