.bag-setting {
    background: #F8F8F8;
}

.bag-setting .container {
    padding-top: 20px;
}

.bag-setting .box {
    border: 1px solid #EEEEEE;
    background: #fff;
    padding: 20px 80px;
    margin-top: 20px;
    margin-bottom: 30px;
    position: relative;
}

.bag-setting .box .title-box {
    padding-bottom: 20px;
    border-bottom: 1px solid #EEEEEE;
    display: flex;
    justify-self: start;
    align-items: center;
}

.bag-setting .box .title-box .blue-line {
    width: 6px;
    height: 28px;
    border-radius: 3px;
    background: #32B6F4;
    margin-right: 10px;
}

.bag-setting .box .title-box .title {
    margin: 0;
}

.bag-setting .box .body-box {
    display: grid;
    grid-template-columns: 500px 150px 300px;
    border-bottom: 1px solid #EEEEEE;
    padding: 20px 0;
}

.bag-setting .box .body-box .bag-description {
    width: 280px;
    min-height: 100px;
    background: #F8F8F8;
    border: 1px solid #EEEEEE;
    border-radius: 4px;
    padding: 8px;
}

.bag-setting .box .body-box .random-color-btn {
    color: #32B6F4;
    cursor: pointer;
    margin-top: 10px;
}

.bag-setting .box .middle {
    display: flex;
    align-items: center;
}

.bag-setting .box .btn-box {
    margin-left: 115px;
    margin-top: 30px;
}

.bag-setting .box .btn-box .cancel {
    margin-right: 30px;
}

.bag-setting .upload-download-btns{
    position: absolute;
    top: 18px;
    right: 0;
}
.bag-setting .upload-download-btns button{
    margin: 0 10px;
    min-width: 110px;
    background: linear-gradient(#46D9FE, #33A8DE);
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    padding: 0 20px;
}