.mobile-shop-tips {
    margin-top: 50px;
    text-align: center;
    color: #333333;
}

.mobile-shop {
    padding: 30px;
    background-image: linear-gradient(to bottom, #FFFFFF, #E9F6FC);
}

.mobile-shop .title {
    margin: 0 60px;
    margin-top: 140px;
    margin-bottom: 20px;
}

.mobile-shop .title img {
    width: 100%;
}

.mobile-shop .select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 12px;
    font-size: 16px;
    background-image: linear-gradient(to right, #5FB8F1, #92E0FC);
    color: #fff;
    margin-bottom: 14px;
}

.mobile-shop .select .select-btn {
    width: 24px;
}

.mobile-shop .select-box {
    height: 0;
    overflow: hidden;
    transition: all .5s;
}

.mobile-shop .select-box.show {
    margin-bottom: 20px;
    height: auto;
}

.mobile-shop .triangle-up {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
    margin-left: 300px;
}

.mobile-shop .select-detail {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 0 14px;
}

.mobile-shop .select-detail .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #333333;
    line-height: 3;
    border-bottom: 1px solid #F7F7F7;
}

.mobile-shop .select-detail .item img {
    width: 16px;
}

.mobile-shop .bag-box {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
}

.mobile-shop .bag-box img {
    width: 100%;
    border-radius: 6px;
}

.mobile-shop .bag-box .item {
    position: relative;
}

.mobile-shop .bag-box .item .tag {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50px;
    text-align: center;
    line-height: 20px;
    border-radius: 6px 0;
    color: #fff;
    font-size: 12px;
    background: #3BAEE4;
}

.mobile-shop-modal-box .ant-modal-content {
    border-radius: 12px;
    overflow: hidden;
}

.mobile-shop-modal .cover {
    width: 100%;
}

.mobile-shop-modal .text-box {
    padding: 20px;
}

.mobile-shop-modal .text-box .title {
    color: #333333;
}

.mobile-shop-modal .text-box .introduction {
    font-size: 12px;
    color: #333333;
}

.mobile-shop-modal .text-box .detail-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    margin-bottom: 20px;
}

.mobile-shop-modal .text-box .buy {
    width: 100%;
    height: 50px;
    font-size: 16px;
}

.mobile-shop-modal .text-box .buy.grey {
    background: #EEEEEE;
    color: #AEAEAE;
}

.mobile-shop-modal-rq-code {
    width: 100%;
}
