.c-cards {
    width: 280px;
    padding: 15px;
    background: #fff;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 8px 14px 0 rgba(185,188,189,0.31);
    position: relative;
    height: 470px;
    margin: 10px;
}
.c-cards:nth-child(even){
    margin-top: 65px;
}
.c-card-img{
    background-size: cover!important;
    width: 100%;
    height: 130px;
    border-radius: 8px;
    background: linear-gradient(to bottom right, #D5DEED, #BDCBDE, #A4B7CF);
}
.private-bg, .private-content{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.private-content{
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.private-title, .private-updatetime, .percent-content{
    color: #fff;
}
.percent-top{
    border-bottom: 1px solid #fff;
    font-size:20px;
    padding-bottom: 5px;
    width: 100px;
    margin: 0 auto 5px;
}
.percent-bottom{
    font-size:12px;
}
.private-btn{
    font-size: 16px;
    border: none;
    padding: 10px 30px;
    width: auto;
    height: auto;
    color: #52bff3;
    margin-bottom: 20px;
    display: inline-block;
    background: #fff;
    border-radius: 5px;
}
.chapters-container{
    display: flex;
    flex-direction: column;
    color: #52bff3;
    padding: 0;
    height: 255px;
    overflow-y: auto;
}
.chapters-item{
    display: unset;
    padding: 8px 15px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.chapters-item:nth-child(odd){
    background: #f3fcff;
}
.chapters-item:nth-child(even){
    background: #e6f8fe;
}
.bottom-btn{
    display: flex;
    justify-content: space-between;
}
.bottom-btn button{
    width: auto;
    height: auto;
    border: none;
    background: #f6f6f6;
    color: #aaa;
    padding: 10px;
}
.bottom-btn button:focus, .bottom-btn button:active, .bottom-btn button:hover{
    color: #52bff3;
    border: 1px solid #52bff3;
    background: #e6f8fe;
}
.chapters-active{
    color: #fff;
    background: #52bff3;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    padding-top: 2px;
    font-size: 12px;
}
.chapters-inactive{
    width: 16px;
    height: 16px;
    border: 1px solid #52bff3;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
}
.empty-link{
    width: 100%;
    height: 100%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}
.empty-title-child{
    font-size: 16px;
    font-weight: normal;
    padding-top: 5px;
}
.empty-add{
    font-style: normal;
    font-size: 60px;
    color: #fff;
    margin-bottom: 100px;
}